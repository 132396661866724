<template>
  <div class="no-p">
    <EmployeeCardInfo v-if="!isLoading" :employeeData="employee" />
    <EmployeeDataTabs />

    <router-view
      class="custom-tab-outer-content"
      :employeeData="employee"
    ></router-view>
  </div>
</template>

<script>
import { STATUS } from "@/utils/constants";
import EmployeeCardInfo from "@/components/employees/employees/EmployeeCardInfo.vue";
import EmployeeDataTabs from "@/components/employees/employeeData/EmployeeDataTabs.vue";
import Employee from "@/models/employees/employees/Employee";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "EmployeeData",
  mixins: [createToastMixin],
  components: {
    EmployeeCardInfo,
    EmployeeDataTabs,
  },
  props: ["employeeToken"],
  data() {
    return {
      isLoading: true,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      employee: new Employee(),
    };
  },
  methods: {
    async getEmployeeDetails() {
      this.isLoading = true;
      this.employee.employeeToken = this.$store.getters.employeeToken;
      try {
        let response = await this.employee.getEmployeeDetails(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.employee.fillData(response.data.employeeData);
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
          this.$router.push({ name: "Employees" }).catch(() => {});
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.$router.push({ name: "Employees" }).catch(() => {});
      }
      this.isLoading = false;
    },
  },
  computed: {},
  async created() {
    if (this.employeeToken) {
      this.$store.dispatch("updateEmployeeToken", this.employeeToken);
    }
    this.getEmployeeDetails();
  },
};
</script>
